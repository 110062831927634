import './src/styles/global.css';
import clevertap from 'clevertap-web-sdk';

const CLEVERTAP_CONFIG = require('./config/clevertap');

// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    clevertap.init(
      process.env.GATSBY_APP_ENV === 'prod'
        ? CLEVERTAP_CONFIG.PROJECT_ID.PROD
        : CLEVERTAP_CONFIG.PROJECT_ID.TEST,
      CLEVERTAP_CONFIG.REGION,
    );
  }
};
