exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/BlogDetail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-preview-js": () => import("./../../../src/pages/blog-preview.js" /* webpackChunkName: "component---src-pages-blog-preview-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-download-app-js": () => import("./../../../src/pages/download-app.js" /* webpackChunkName: "component---src-pages-download-app-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hubungi-kami-js": () => import("./../../../src/pages/hubungi-kami.js" /* webpackChunkName: "component---src-pages-hubungi-kami-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalkulator-js": () => import("./../../../src/pages/kalkulator.js" /* webpackChunkName: "component---src-pages-kalkulator-js" */),
  "component---src-pages-landing-activewoman-js": () => import("./../../../src/pages/landing/activewoman.js" /* webpackChunkName: "component---src-pages-landing-activewoman-js" */),
  "component---src-pages-landing-millenialprofessional-js": () => import("./../../../src/pages/landing/millenialprofessional.js" /* webpackChunkName: "component---src-pages-landing-millenialprofessional-js" */),
  "component---src-pages-landing-youngentrepreneur-js": () => import("./../../../src/pages/landing/youngentrepreneur.js" /* webpackChunkName: "component---src-pages-landing-youngentrepreneur-js" */),
  "component---src-pages-peluang-deposito-js": () => import("./../../../src/pages/peluang-deposito.js" /* webpackChunkName: "component---src-pages-peluang-deposito-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-referral-guide-download-js": () => import("./../../../src/pages/referral/guide-download.js" /* webpackChunkName: "component---src-pages-referral-guide-download-js" */),
  "component---src-pages-referral-index-js": () => import("./../../../src/pages/referral/index.js" /* webpackChunkName: "component---src-pages-referral-index-js" */),
  "component---src-pages-referral-learn-more-js": () => import("./../../../src/pages/referral/learn-more.js" /* webpackChunkName: "component---src-pages-referral-learn-more-js" */),
  "component---src-pages-referral-register-index-js": () => import("./../../../src/pages/referral/register/index.js" /* webpackChunkName: "component---src-pages-referral-register-index-js" */),
  "component---src-pages-referral-register-thank-you-js": () => import("./../../../src/pages/referral/register/thank-you.js" /* webpackChunkName: "component---src-pages-referral-register-thank-you-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blogDetail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-plain-page-detail-js": () => import("./../../../src/templates/plainPageDetail.js" /* webpackChunkName: "component---src-templates-plain-page-detail-js" */),
  "component---src-templates-promo-detail-js": () => import("./../../../src/templates/promoDetail.js" /* webpackChunkName: "component---src-templates-promo-detail-js" */)
}

